
import './App.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useRef, useState, useEffect } from 'react'
import { css } from '@emotion/react'

import { Helpers } from './services/Columns'
import log from './services/Logger'

function App() {
  const gridRef = useRef();
  const [columnDefs, setColumnDefs] = useState([])
  const [rowData, setRowData] = useState([])
  const [filterText, setFilterText] = useState('')

  const grid_style = css`
    font-size:12px;
    width: 90%;
    .ag-header-cell-filtered {
      background-color: #E0E0E0 !important;
    }
  `
  const onFilterTextBoxChanged = (value) =>{
    setFilterText(value)
    gridRef?.current.api.setQuickFilter(value);
  }
  const gridLoadedEvent = (eventDetails )=> {
    eventDetails.api.sizeColumnsToFit();
  }
  const rowStyleEvent = (eventDetails) => {
    log("row style event triggered")
    // console.log(eventDetails)
  }

  useEffect(() => {    
    log("Fetching contents")
    async function fetchData() {
      const response = await fetch("https://api.lapstax.co.za/get/")
      const responseJSON = await response.json()
      log(responseJSON.bets.length)
      const parsedData = responseJSON.bets.map(bet => {
        const parsedBetData = {
          id: bet.id,
          code: bet.code,
          date_uploaded: new Date(bet.date_created).toLocaleString(),
          active: bet.active,
          date_created: new Date(bet.raw.code_data.time).toLocaleString(),
          valid_bet: !bet.raw.code_data.isReturned
        }
        if (!parsedBetData.valid_bet)
          return parsedBetData
        if (!bet.raw.code_data.results)  
          log(bet.raw.code_data)
        bet.raw.code_data.results.forEach((betResult, index) => {
          parsedBetData[`ball_${index}`] = betResult.number
          parsedBetData[`ball_${index}_color`] = betResult.color
        })
        return parsedBetData
      })
      setRowData(parsedData)
      setColumnDefs(Helpers.DefineColumns(Object.keys(parsedData[0])))
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (rowData?.length > 0) {
      log("fetched stored data")
      log(rowData)
    }
  }, [rowData])
  
  return (
    <div className="App">
      <input type="text" onChange={(e) => onFilterTextBoxChanged(e.target.value)} value={filterText}/>
      <div className='ag-theme-alpine' style={{height: '800px'}} >
        <AgGridReact 
            rowData={rowData} 
            columnDefs={columnDefs}
            tooltipHideDelay={4000}
            tooltipShowDelay={0}
            ref={gridRef}
            getRowStyle={rowStyleEvent}
            css={grid_style}
            rowHeight={48}
            onGridReady={gridLoadedEvent}
            onGridSizeChanged={gridLoadedEvent}
            rowSelection={'single'}
        >
        </AgGridReact>
      </div>  
    </div>
  );
}

export default App;
